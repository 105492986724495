import type { ContentReference, TaskFiles, UserProgress } from '@/types';
import type { UploadFileInfo } from 'naive-ui/es/upload/src/public-types';

interface DataStoreState {
  hasFetchedProgress: boolean;
  hasMadeChanges: boolean;
  userProgress: UserProgress[];
}

/**
 * Data store for keeping track of user input and files.
 *
 * @example Getting values
 * ```ts
 * const { userProgress } = useDataStore();
 * console.log('User progress:', userProgress.value);
 * ```
 * @example Setting values
 * ```ts
 * const { saveFiles, saveTextArea } = useDataStore();
 * const contentRef = getContentRef();
 * saveFiles(files, contentRef);
 * setStudentNotes(textarea, contentRef);
 * ```
 * @package fun-academy-campus
 */
export default function useDataStore() {
  const { getContentRef } = useContentStore();
  const { getFilesForEntry } = useFirebase();
  const { $logger } = useNuxtApp();

  const defaultState: DataStoreState = {
    hasFetchedProgress: false,
    hasMadeChanges: false,
    userProgress: [],
  };

  const state = useState<DataStoreState>('dataStore', () => defaultState);

  const userProgress = computed<DataStoreState['userProgress']>(
    () => state.value.userProgress
  );

  const hasFetchedProgress = computed<DataStoreState['hasFetchedProgress']>(
    () => state.value.hasFetchedProgress
  );

  const hasMadeChanges = computed<DataStoreState['hasMadeChanges']>(
    () => state.value.hasMadeChanges
  );

  /** Gets the editor's textarea value for the current task. */
  function getEditorNotes(taskSlug: string): string {
    return (
      state.value.userProgress.find((e) => e.task === taskSlug)?.editorNotes ??
      ''
    );
  }

  /** Gets the image url of a specific file index from the specified task. */
  function getImageUrl(taskSlug: string, index: number): string {
    const entry = state.value.userProgress.find((e) => e.task === taskSlug);
    const files = entry?.files ?? [];
    const file = files.at(index);
    return file?.url ?? '';
  }

  /** Gets the textarea value for the current task. */
  function getTextareaValue(taskSlug: string): string {
    return (
      state.value.userProgress.find((e) => e.task === taskSlug)?.textarea ?? ''
    );
  }

  /** Gets an upload value based on the task slug. */
  async function getUploadValue(taskSlug: string): Promise<TaskFiles> {
    const contentRef = getContentRef();
    if (!contentRef) return;
    const remoteFiles = await getFilesForEntry(contentRef.task);
    if (remoteFiles.length) {
      $logger.debug('Files after mapping:', remoteFiles);
    } else {
      $logger.info('No remote files found:', contentRef);
    }
    const entryIndex = state.value.userProgress.findIndex(
      (e) => e.task === taskSlug
    );
    if (-1 !== entryIndex) {
      state.value.userProgress[entryIndex].files = remoteFiles || [];
    }
    const entry = state.value.userProgress.find((e) => e.task === taskSlug);
    if (entry) entry.files = remoteFiles || [];
    return remoteFiles;
  }

  /**
   * Marks files for deletion. Actual removal
   * happens when the user saves the changes.
   */
  function markFileForDeletion(
    file: UploadFileInfo,
    contentRef: ContentReference
  ): void {
    if (!file) return;
    $logger.info('Marking file for deletion:', file);
    const index = state.value.userProgress.findIndex(
      (e) => e.task === contentRef.task
    );
    if (0 <= index) {
      $logger.debug('Pushing to existing stack of files marked for deletion.');
      state.value.userProgress[index].filesToBeDeleted?.push(file);
    } else {
      const newEntry: UserProgress = {
        app: 'campus',
        ...contentRef,
        files: [],
        filesToBeDeleted: [file],
        timestamp: new Date().toISOString(),
      };
      $logger.debug('Creating a new progress entry:', newEntry);
      state.value.userProgress.push(newEntry);
    }
  }

  function reset(): void {
    state.value = defaultState;
  }

  /** Empties the queue of files marked for deletion. */
  function resetDeletedFiles(contentRef: ContentReference): void {
    const index = state.value.userProgress.findIndex(
      (e) => e.task === contentRef.task
    );
    if (0 <= index) {
      state.value.userProgress[index].filesToBeDeleted = [];
    } else {
      state.value.userProgress.push({
        app: 'campus',
        ...contentRef,
        files: [],
        filesToBeDeleted: [],
        timestamp: new Date().toISOString(),
      });
    }
  }

  /** Saves files to state for a content entry. */
  function saveFilesToState(
    files: TaskFiles,
    contentRef: ContentReference
  ): void {
    if (!files) return;
    $logger.info('Saving files to state:', files);
    const index = state.value.userProgress.findIndex(
      (e) => e.task === contentRef.task
    );
    if (0 <= index) {
      state.value.userProgress[index].files = files;
    } else {
      state.value.userProgress.push({
        app: 'campus',
        ...contentRef,
        files,
        filesToBeDeleted: [],
        timestamp: new Date().toISOString(),
      });
    }
  }

  /** Sets the textarea for a progress entry. */
  function setEditorNotes(
    editorNotes: string,
    contentRef: ContentReference
  ): void {
    $logger.debug('Setting editor notes:', editorNotes);
    const index = state.value.userProgress.findIndex(
      (e) => e.task === contentRef.task
    );
    if (-1 !== index) {
      state.value.userProgress[index].editorNotes = editorNotes;
    } else {
      state.value.userProgress.push({
        app: 'campus',
        files: [],
        ...contentRef,
        editorNotes,
        timestamp: new Date().toISOString(),
      });
    }
  }

  /** Sets the `hasFetchedProgress` state used for loading states. */
  function setHasFetchedProgress(value: boolean): void {
    $logger.debug('Setting has fetched progress:', value);
    state.value.hasFetchedProgress = value;
  }

  /** Sets `hasMadeChanges` state used by individual tasks. */
  function setHasMadeChanges(hasMadeChanges: boolean): void {
    $logger.debug('Setting has made changes:', hasMadeChanges);
    state.value.hasMadeChanges = hasMadeChanges;
  }

  /** Sets progress for an entire module. */
  function setModuleProgress(
    moduleProgress: UserProgress[],
    files: Record<string, UploadFileInfo[]>,
    moduleSlug?: string
  ): void {
    try {
      if (!files) {
        throw new Error('No files provided for setting module progress.');
      } else if (!moduleSlug) {
        throw new Error(
          'Unable to update module progress: Reference is missing.'
        );
      }
      $logger.info(`Setting progress for module ${moduleSlug}…`);
      $logger.debug('Module progress before:', moduleProgress);
      $logger.debug('Incoming files:', files);
      $logger.debug('Tasks affected:', Object.keys(files));
      for (const taskSlug of Object.keys(files)) {
        $logger.info(`Processing task ${taskSlug}…`);
        const taskIndex = moduleProgress.findIndex((e) => e.task === taskSlug);
        if (-1 === taskIndex) {
          $logger.log('No progress found for:', taskSlug);
          continue;
        }
        $logger.debug(
          `Existing files for task ${taskSlug}:`,
          moduleProgress[taskIndex].files
        );
        $logger.debug(`New files for ${taskSlug}:`, files[taskSlug]);
        moduleProgress[taskIndex].files = files[taskSlug];
      }
      setUserProgress([
        ...state.value.userProgress.filter((e) => e.module !== moduleSlug),
        ...moduleProgress,
      ]);
    } catch (error) {
      $logger.error(error);
    }
  }

  /** Sets the textarea for a progress entry. */
  function setStudentNotes(
    textarea: string,
    contentRef: ContentReference
  ): void {
    $logger.debug('Setting text area:', textarea);
    const index = state.value.userProgress.findIndex(
      (e) => e.task === contentRef.task
    );
    if (-1 !== index) {
      state.value.userProgress[index].textarea = textarea;
    } else {
      state.value.userProgress.push({
        app: 'campus',
        files: [],
        ...contentRef,
        textarea,
        timestamp: new Date().toISOString(),
      });
    }
  }

  /** Sets user progress. */
  function setUserProgress(userProgress: UserProgress[]): void {
    $logger.debug('Setting user progress:', userProgress);
    state.value.userProgress = userProgress;
  }

  function updateFileUrl(
    url: string,
    fileIndex: number,
    contentRef: ContentReference
  ): void {
    const index = state.value.userProgress.findIndex(
      (e) => e.task === contentRef.task
    );
    $logger.debug(`Index for file ${url}`, index);
    const fileEntry = state.value.userProgress.at(index)?.files?.at(fileIndex);
    $logger.debug('File entry:', fileEntry);
    if (!fileEntry?.fullPath) return;
    fileEntry.url = url;
    $logger.debug(
      'File entry after update:',
      state.value.userProgress.at(index)?.files?.at(fileIndex)
    );
  }

  return {
    getEditorNotes,
    getImageUrl,
    getTextareaValue,
    getUploadValue,
    hasFetchedProgress,
    hasMadeChanges,
    markFileForDeletion,
    reset,
    resetDeletedFiles,
    saveFilesToState,
    setEditorNotes,
    setHasFetchedProgress,
    setHasMadeChanges,
    setModuleProgress,
    setStudentNotes,
    setUserProgress,
    updateFileUrl,
    userProgress,
  };
}
