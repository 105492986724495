import { ClientOnly } from '#components';
import { NFormItem, NInput } from 'naive-ui';
import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'ContentEditorNotes',
  props: {
    label: { default: '', type: String },
    placeholder: { default: '', type: String },
  },
  setup(props) {
    const { currentTask } = useContentStore();
    const currentUser = useCurrentUser();
    const { getEditorNotes, setHasMadeChanges, userProgress } = useDataStore();
    const { selectedUser } = useUserManagement();
    const { isEditor } = useQueryData();
    const { t } = useI18n();

    const editorNotes = ref<string>('');
    const editorNotesLabel = computed<string>(() =>
      isEditor.value && selectedUser.value?.value !== currentUser.value?.uid
        ? t('responseLabelTeacher')
        : props.label || t('editorNotesLabel')
    );
    const showField = computed<boolean>(
      () =>
        Boolean(editorNotes.value) ||
        (isEditor.value &&
          !!selectedUser.value &&
          selectedUser.value.value !== currentUser.value?.uid)
    );

    function handleUpdate(value: string): void {
      editorNotes.value = value;
      if (value === getEditorNotes(currentTask.value?.slug ?? '')) {
        setHasMadeChanges(false);
      } else {
        setHasMadeChanges(true);
      }
    }

    watch(
      userProgress,
      (value) => {
        if (!value) return;
        editorNotes.value = getEditorNotes(currentTask.value?.slug ?? '');
      },
      { deep: true, immediate: true }
    );

    return {
      currentUser,
      editorNotes,
      editorNotesLabel,
      handleUpdate,
      isEditor,
      selectedUser,
      showField,
    };
  },
  render(): VNode | null {
    if (!this.showField) return null;
    return (
      <ClientOnly>
        <NFormItem class="mt-8" label={this.editorNotesLabel}>
          <NInput
            autosize={{ minRows: 6 }}
            disabled={!this.isEditor}
            inputProps={{ name: 'editor-notes' }}
            onChange={this.handleUpdate}
            placeholder={this.$t('editorNotesPlaceholder')}
            type="textarea"
            v-model:value={this.editorNotes}
          />
        </NFormItem>
      </ClientOnly>
    );
  },
});
