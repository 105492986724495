import type {
  NavigationGuardReturn,
  RouteLocationNormalized,
} from 'vue-router';

/**
 * Nuxt middleware for setting the current content.
 * @package fun-academy-campus
 */
export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
    if (import.meta.server) return;
    const {
      setCurrentCourse,
      setCurrentModule,
      setCurrentTask,
      setContent,
      setHasFetchedContent,
      // setIsAuthorizedToViewContent,
    } = useContentStore();
    const { $i18n, $logger } = useNuxtApp();
    // const currentUser = useCurrentUser();
    const id: string = resolveParam(to.params.id);

    const localeCode: string = $i18n.locale.value;
    $logger.debug(
      'Processing current path',
      to.path,
      'with locale:',
      localeCode
    );
    const [courseSlug, moduleSlugPartial, taskSlugPartial] = id.split('.');

    const moduleSlug: string = `${courseSlug}.${moduleSlugPartial}`;
    const taskSlug: string = `${moduleSlug}.${taskSlugPartial}`;

    const caseCourse: RegExp = /^[1-9][0-9]?$/;
    const caseModule: RegExp = /^[1-9][0-9]?\.[1-9][0-9]?$/;
    const caseTask: RegExp = /^[1-9][0-9]?\.[1-9][0-9]?\.[1-9]$/;

    // if (caseTask.test(id)) {
    //   $logger.info('Checking authorization for path:', to.path);

    //   const headers: Headers = new Headers();
    //   headers.set('x-user-id', currentUser.value?.uid ?? '');

    //   const { data: isAuthorized } = await useAsyncData<boolean>(
    //     `course-${courseSlug}-authorization`,
    //     async (): Promise<boolean> =>
    //       Boolean(
    //         (
    //           await useFetch<boolean>(`/api/course/${courseSlug}`, {
    //             headers,
    //             method: 'get',
    //           })
    //         ).data.value
    //       )
    //   );

    //   setIsAuthorizedToViewContent(Boolean(isAuthorized.value));

    //   if (!isAuthorized.value) {
    //     $logger.info('User is not authorized to view content:', to.path);
    //     setContent(null);
    //     setHasFetchedContent(false);
    //     return navigateTo(useLocalePath()({ name: 'access-denied' }));
    //   }
    // }

    switch (true) {
      case caseTask.test(id):
        $logger.debug('Setting new task:', taskSlug);
        await setCurrentTask(taskSlug, localeCode);
        await setCurrentModule(moduleSlug, localeCode);
        await setCurrentCourse(courseSlug, localeCode);
        break;
      case caseModule.test(id):
        $logger.debug('Setting new module:', moduleSlug);
        await setCurrentTask('', localeCode);
        await setCurrentModule(moduleSlug, localeCode);
        await setCurrentCourse(courseSlug, localeCode);
        break;
      case caseCourse.test(id):
        $logger.debug('Setting new course:', courseSlug);
        await setCurrentTask('', localeCode);
        await setCurrentModule('', localeCode);
        await setCurrentCourse(courseSlug, localeCode);
        break;
      default:
        $logger.debug('Resetting content…');
        await setCurrentTask('', localeCode);
        await setCurrentModule('', localeCode);
        await setCurrentCourse('', localeCode);
    }

    setContent(null);
    setHasFetchedContent(false);
  }
);
