import { NButton, NResult } from 'naive-ui';
import type { VNode } from 'vue';
import { I18nT } from 'vue-i18n';

import { NuxtLink } from '#components';
import Character from '@/assets/characters/4.svg?component';

/**
 * Renders the content for an unauthorized page.
 * @package fun-academy-campus
 */
export default defineNuxtComponent({
  name: 'Unauthorized',
  setup() {
    const { t } = useI18n();
    const localePath = useLocalePath();
    const route = useRoute();
    const redirectPath = computed<string>((): string =>
      localePath({ name: route.query?.redirect?.toString() ?? 'index' })
    );
    async function handleClick(): Promise<ReturnType<typeof navigateTo>> {
      await navigateTo(redirectPath.value);
    }
    useSeoMeta({ title: t('accessDenied') });
    return { handleClick };
  },
  render(): VNode {
    return (
      <article
        class="max-w-prose w-full transition-opacity duration-300"
        itemscope
        itemtype="https://schema.org/Article"
      >
        <meta content="Fun Academy" itemprop="author" />
        <NResult
          class="max-w-screen-md text-center"
          title={this.$t('accessDenied')}
        >
          {{
            default: (): VNode => (
              <>
                <p>{this.$t('accessDeniedDescription')}</p>
                <I18nT keypath="accessDeniedFooter" tag="p">
                  <NuxtLink
                    class="gravatar-link"
                    external
                    href={`mailto:${this.$t('contactEmail')}`}
                    target="_blank"
                  >
                    {this.$t('contactEmail')}
                  </NuxtLink>
                </I18nT>
              </>
            ),
            icon: (): VNode => (
              <Character class="mx-4 my-2 max-h-30 w-full md:max-h-40" />
            ),
            footer: (): VNode => (
              <div class="m-auto max-w-xs flex justify-around">
                <NButton
                  onClick={this.handleClick}
                  round
                  size="large"
                  type="tertiary"
                >
                  {this.$t('goBack')}
                </NButton>
              </div>
            ),
          }}
        </NResult>
      </article>
    );
  },
});
