import type { BaseDocument, ContentReference, UserRole } from '@/types';
import type {
  ListResult,
  StorageReference,
  UploadMetadata,
} from 'firebase/storage';
import type { UploadFileInfo } from 'naive-ui/es/upload/src/public-types';
import type { RouteLocationNormalized } from 'vue-router';

/**
 * Gets the admin role from a group of user roles.
 */
export function getAdminRole(userRoles?: UserRole[]): UserRole | undefined {
  return userRoles?.find((doc: BaseDocument): boolean => 'Admin' === doc?.name);
}

/**
 * Gets a page name based on the route given by separating
 * the localized suffix added by the I18n module.
 *
 * @returns {string} The page name.
 */
export function getPageName(route: RouteLocationNormalized): string {
  return String(route.name).split('_')[0];
}

/**
 * Generates meta data for Firebase uploads
 * based on the file and content reference given.
 *
 * @returns {UploadMetadata} The meta data for the upload.
 */
export function getUploadMetadata(
  file: UploadFileInfo,
  contentRef: ContentReference
): UploadMetadata {
  return {
    /**
     * Served as the 'Content-Disposition' header on object download.
     */
    // Commented out, since Firestore sets this automatically
    // contentDisposition: `inline; filename*=utf-8''${file?.name}`,
    /**
     * Served as the 'Content-Type' header on object download.
     */
    contentType: resolveMimeType(file),
    /**
     * Additional user-defined custom metadata.
     */
    customMetadata: {
      ...contentRef,
    },
  };
}

/**
 * Determines the URL of a file stored in Firebase.
 *
 * If the url does not start with 'gs:', it will
 * simply return whatever string was passed in.
 *
 * @returns {string} The url of the video.
 */
export async function getFirebaseStorageUrl(url?: string): Promise<string> {
  if (!url) return '';
  if (url.startsWith('gs:')) {
    const storage = useFirebaseStorage();
    const fileRef = storageRef(storage, url);
    url = (await useStorageFileUrl(fileRef)?.promise?.value) ?? url;
  }
  return url;
}

/**
 * Gets the file name from an URL.
 */
export function getFileNameFromUrl(url?: string): string {
  return url?.split('/').pop() ?? '';
}

/**
 * Checks if a slug is a course.
 */
export function isCourse(courseSlug?: string): boolean {
  return /^[1-9][0-9]?$/.test(courseSlug ?? '');
}

/**
 * Checks if a slug is the last module of a course.
 */
export function isLastModuleTask(taskSlug?: string): boolean {
  const { $logger } = useNuxtApp();
  const { availableModulesCount } = useContentStore();
  const isLast = (taskSlug ?? '').endsWith(`.${availableModulesCount.value}.5`);
  $logger.debug('Last module:', taskSlug, isLast);
  return isLast;
}

/**
 * Checks if a slug is the last task of a course.
 */
export function isLastTask(taskSlug?: string): boolean {
  return /^[1-9][0-9]?\.[1-9][0-9]?\.5$/.test(taskSlug ?? '');
}

/**
 * Checks if a slug is a module.
 */
export function isModule(moduleSlug?: string): boolean {
  return /^[1-9][0-9]?\.[1-9][0-9]?$/.test(moduleSlug ?? '');
}

/**
 * Checks if a slug is a task.
 */
export function isTask(taskSlug?: string): boolean {
  return /^[1-9][0-9]?\.[1-9][0-9]?\.[1-9]$/.test(taskSlug ?? '');
}

/**
 * Checks if a given slug is a text task.
 */
export function isTextTask(taskSlug?: string): boolean {
  return /^[1-9][0-9]?\.[1-9][0-9]?\.2$/.test(taskSlug ?? '');
}

/**
 * Converts a list results from Firebase to a UploadFileInfo array
 * to make it compatible with Naive UI's upload form.
 * @see {@link https://www.naiveui.com/en-US/dark/components/upload}
 */
export function listResultToFileInfo(
  list: ListResult
): Promise<UploadFileInfo[]> {
  return Promise.all(
    list.items.map(
      async (listItem: StorageReference): Promise<UploadFileInfo> => {
        const ref: StorageReference = storageRef(
          listItem.storage,
          listItem.fullPath
        );
        const url: string | undefined =
          (await useStorageFileUrl(ref).promise.value) ?? undefined;
        const { metadata, refresh } = useStorageFileMetadata(ref);
        await refresh();
        return {
          fullPath: listItem.fullPath,
          id: listItem.name,
          name: listItem.name,
          percentage: 100,
          status: 'finished',
          type: metadata.value?.contentType,
          url,
        };
      }
    )
  );
}

/**
 * Resolves MIME types based on the UploadFileInfo given.
 * @returns {string | undefined} The MIME type of the file.
 */
function resolveMimeType(file: UploadFileInfo): string | undefined {
  return file.type ?? undefined;
}

/**
 * Resolves the given router param and returns it as a string.
 * @returns {string} The router param as a string.
 */
export function resolveParam(param: string | string[]): string {
  return param ? (Array.isArray(param) ? param[0] : param) : '';
}
