import { NCollapse, NCollapseItem } from 'naive-ui';
import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'ContentCollapse',
  props: {
    index: { default: '', type: String, required: true },
    title: { default: '', type: String, required: true },
  },
  render(): VNode {
    return (
      <NCollapse defaultExpandedNames="expand">
        <NCollapseItem
          name={/^[1-2]$/.test(this.index) ? 'expand' : undefined}
          title={this.title}
        >
          {this.$slots.default?.()}
        </NCollapseItem>
      </NCollapse>
    );
  },
});
