import type { ContentReference, UserProgress } from '@/types';
import { NForm, type UploadFileInfo } from 'naive-ui';
import type { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import type { ComponentPublicInstance, VNode } from 'vue';

export default defineNuxtComponent({
  name: 'ContentForm',
  setup() {
    const currentUser = useCurrentUser();
    const { getContentRef } = useContentStore();
    const { saveEntryToFirestore } = useFirebase();
    const {
      hasMadeChanges,
      resetDeletedFiles,
      saveFilesToState,
      setEditorNotes,
      setHasMadeChanges,
      setStudentNotes,
      userProgress,
    } = useDataStore();
    const { getEntryFromFirestore, removeTaskFiles, uploadFiles } =
      useFirebase();
    const { t } = useI18n();
    const { $logger } = useNuxtApp();
    const { isEditor } = useQueryData();
    const { selectedUser } = useUserManagement();

    onBeforeRouteLeave((): boolean | undefined => {
      if (hasMadeChanges.value) {
        return window.confirm(t('confirmLeavePage'));
      }
    });

    const contentRef: ContentReference = getContentRef();
    const formElement = ref<
      ComponentPublicInstance<HTMLFormElement> | undefined
    >(undefined);
    const message: MessageApiInjection = useMessage();

    async function handleSubmit(event: Event): Promise<void> {
      event.preventDefault();
      $logger.debug('Submit content event:', event);

      const form: HTMLFormElement = formElement.value?.$el as HTMLFormElement;

      let editorNotes: string =
        form.querySelector<HTMLTextAreaElement>('[name="editor-notes"]')
          ?.value ?? '';
      let studentNotes: string =
        form.querySelector<HTMLTextAreaElement>('[name="student-notes"]')
          ?.value ?? '';

      const entryIndex: number =
        userProgress.value?.findIndex((e) => e.task === contentRef.task) ?? -1;

      if (-1 === entryIndex || !userProgress.value) {
        $logger.warn(
          'Something might be wrong. Unable to determine index for:',
          contentRef
        );
        return;
      }

      const filesToBeDeleted: UploadFileInfo[] =
        userProgress.value[entryIndex]?.filesToBeDeleted ?? [];
      await removeTaskFiles(contentRef, filesToBeDeleted);
      resetDeletedFiles(contentRef);

      const firebaseProgress: UserProgress | null = await getEntryFromFirestore(
        contentRef,
        'task'
      );

      const newProgress: UserProgress = {
        ...Object.fromEntries(
          Object.entries(
            firebaseProgress ?? userProgress.value[entryIndex]
          ).filter((entry): boolean => 'files' !== entry[0])
        ),
        app: 'campus',
        filesToBeDeleted: [],
        timestamp: new Date().toISOString(),
      };

      // Editor
      if (
        isEditor.value &&
        currentUser.value?.uid !== selectedUser.value?.value
      ) {
        newProgress.editorNotes = editorNotes;
        studentNotes = firebaseProgress?.textarea ?? studentNotes;
        newProgress.textarea = studentNotes;

        // Student
      } else {
        editorNotes = firebaseProgress?.editorNotes ?? editorNotes;
        newProgress.editorNotes = editorNotes;
        newProgress.textarea = studentNotes;

        // Upload new files
        const files = await uploadFiles(
          userProgress.value[entryIndex].files ?? [],
          contentRef
        );
        saveFilesToState(files, contentRef);
      }

      await saveEntryToFirestore(newProgress);

      setEditorNotes(editorNotes, contentRef);
      setHasMadeChanges(false);
      setStudentNotes(studentNotes, contentRef);

      message.success(t('successSave'));
    }

    onMounted(() => {
      setHasMadeChanges(false);
    });

    return { formElement, handleSubmit };
  },
  render(): VNode {
    return (
      <NForm onSubmit={this.handleSubmit} ref="formElement">
        {this.$slots.default?.()}
      </NForm>
    );
  },
});
