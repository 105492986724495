import type { User } from 'firebase/auth';
import type {
  NavigationGuardReturn,
  RouteLocationNormalized,
} from 'vue-router';

/**
 * Nuxt middleware for preventing users from accessing
 * unauthorized routes.
 * @example
 * ```ts
 * setup() {
 *   definePageMeta({ layout: 'default', middleware: ['auth'] });
 * }
 * ```
 * @package fun-academy-campus
 */
export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
    if (import.meta.server) return;
    const name: string = to.name?.toString() ?? '';
    if (name && AUTH_ROUTES.includes(name)) return;
    const currentUser = (await getCurrentUser()) as User;
    const token: string = await currentUser.getIdToken(true);
    if (token) return;
    return await navigateTo(
      useLocalePath()({
        name: 'sign-out',
        query: { skip: 'loading', redirect: to.fullPath },
      })
    );
  }
);
