import type {
  ContentReference,
  Course,
  LogEvent,
  TaskFiles,
  UserData,
  UserGroup,
  UserProgress,
  UserRole,
  UserWithCustomClaims,
} from '@/types';
import { logEvent as firebaseLogEvent } from 'firebase/analytics';
import type { User } from 'firebase/auth';
import { collection } from 'firebase/firestore';
import type { UploadFileInfo } from 'naive-ui/es/upload/src/public-types';
import type { VueFirestoreDocumentData, _RefFirestore } from 'vuefire';

/**
 * Composable for performing actions with Firebase.
 */
export default function useFirebase() {
  const { getContentRef } = useContentStore();
  const currentUser = useCurrentUser();
  const firebaseStorage = useFirebaseStorage();
  const db = useFirestore();
  const { $analytics, $logger } = useNuxtApp();
  const { selectedUser } = useUserManagement();

  const userId = computed<string>(() =>
    selectedUser.value
      ? String(selectedUser.value.value)
      : String(currentUser.value?.uid)
  );

  const userFolder = computed<string[]>(() =>
    userId.value ? ['public/uploads', userId.value] : []
  );

  /** Generates file reference for Firebase. */
  function generateFileReference(
    fileName: string,
    contentPath?: string
  ): string {
    if (!currentUser?.value?.uid && !selectedUser?.value?.uid) {
      throw new Error('No user found.');
    } else if (!contentPath) {
      throw new Error('No content path given.');
    }
    const path = [...userFolder.value, contentPath, fileName].join('/');
    $logger.debug('Generating file reference:', path);
    return path;
  }

  /** Gets a single entry based on a content reference. */
  async function getEntryFromFirestore(
    contentRef: ContentReference,
    type = 'task'
  ): Promise<UserProgress | null> {
    let response: _RefFirestore<VueFirestoreDocumentData<UserData> | undefined>;
    let userProgress: UserProgress | null = null;
    try {
      const taskId = contentRef.task;
      let existingProgress: UserProgress[] = [];
      if (!taskId) {
        throw new Error(
          `Unable to determine task ID based on content reference: ${JSON.stringify(
            contentRef
          )}`
        );
      }
      if (!userId.value || import.meta.server) return userProgress;
      switch (type) {
        case 'task':
          response = useDocument<UserData>(doc(db, userId.value, 'progress'));
          existingProgress = (await response.promise.value)?.tasks ?? [];
          $logger.debug('User progress from Firestore:', existingProgress);
          if (!response) {
            $logger.log('No existing user progress found.');
            return userProgress;
          }
          if (response.error?.value) throw response.error.value;
          userProgress =
            // Get the latest first
            existingProgress
              .reverse()
              .find(
                (entry: UserProgress): boolean =>
                  entry?.task === contentRef.task
              ) ?? null;
          $logger.debug('Entry data from Firestore:', response.data?.value);
          break;
        default:
          throw new Error(`No case configured for type: ${type}`);
      }
      $logger.debug('User progress:', userProgress);
    } catch (error) {
      $logger.error('Unable to fetch user progress.', { error });
      throw error;
    }
    return userProgress;
  }

  /**
   * Gets a list of files as UploadFileInfo from a user directory.
   * @link https://firebase.google.com/docs/storage/web/list-files
   */
  async function getFilesForEntry(task?: string): Promise<UploadFileInfo[]> {
    if (!task) {
      throw new Error('Unable to fetch files for entry without task slug.');
    }
    const fullPath = [...userFolder.value, task].join('/');
    const userFolderRef = storageRef(firebaseStorage, fullPath);
    const files = await listAll(userFolderRef);
    $logger.debug(`User files in folder ${fullPath}:`, files);
    return listResultToFileInfo(files);
  }

  /** Gets a list of files for a specific module. */
  async function getModuleFiles(
    contentRef?: ContentReference
  ): Promise<Record<string, UploadFileInfo[]>> {
    const module = contentRef?.module;
    if (!contentRef) {
      throw new Error(
        'Unable to fetch module files without content reference.'
      );
    } else if (!module || (module && !isModule(contentRef?.module))) {
      throw new Error(`Incorrect module format: ${contentRef.module}`);
    }
    const moduleFiles: Record<string, UploadFileInfo[]> = {};
    const tasks = [`${module}.1`, `${module}.2`, `${module}.3`, `${module}.4`];
    $logger.debug(`Getting tasks for module ${module}:`, tasks);
    for (const task of tasks) {
      const files = await getFilesForEntry(task);
      if (files.length) {
        $logger.debug('Files after mapping:', files);
      } else {
        $logger.info('No remote files found for content:', contentRef);
      }
      $logger.debug(`User files for task ${task}:`, files);
      moduleFiles[task] = files ?? [];
    }
    $logger.debug('Module files:', moduleFiles);
    return moduleFiles;
  }

  /** Gets all user progress related to a specific module. */
  async function getModuleProgress(
    contentRef: ContentReference,
    type = 'task'
  ): Promise<UserProgress[]> {
    let response: _RefFirestore<VueFirestoreDocumentData<UserData> | undefined>;
    let userProgress: UserProgress[] = [];
    try {
      const taskId = contentRef.task;
      if (!taskId) {
        throw new Error(
          `Unable to determine task ID based on content reference: ${JSON.stringify(
            contentRef
          )}`
        );
      }
      if (!userId.value) return userProgress;
      switch (type) {
        case 'task':
          response = useDocument<UserData>(doc(db, userId.value, 'progress'));
          $logger.debug('User progress from Firestore:', response);
          if (response.error?.value) throw response.error.value;
          userProgress =
            (await response.promise.value)?.tasks
              // Get the latest first
              ?.reverse()
              .filter((entry) => entry.module === contentRef.module) ?? [];
          $logger.debug('Data from Firestore:', response.data.value);
          break;
        default:
          throw new Error(`No case configured for type: ${type}`);
      }
      $logger.debug(
        `User entries for module ${contentRef.module}:`,
        userProgress
      );
    } catch (error) {
      $logger.error('Unable to fetch user progress:', { error });
    }
    return userProgress;
  }

  /** Gets all user progress related to a specific module. */
  async function getUserDiplomas(): Promise<string[]> {
    const userClaims = await getUserClaims();
    const userDiplomas =
      (userClaims?.diplomas?.filter((diploma) =>
        Boolean(diploma)
      ) as string[]) ?? [];
    $logger.debug('User diplomas:', userDiplomas);
    return userDiplomas;
  }

  /** Gets a list of current user claims. */
  async function getUserClaims(): Promise<
    UserWithCustomClaims['customClaims']
  > {
    if (import.meta.server) return [];
    try {
      $logger.info('Getting user claims …');
      const response = await $fetch<UserWithCustomClaims | undefined>(
        `/api/user/claims/${currentUser.value?.uid}`,
        {
          method: 'GET',
          query: { token: await currentUser.value?.getIdToken() },
        }
      );
      $logger.debug('Claims response:', { response });
      return response?.customClaims;
    } catch (error) {
      $logger.error('Unable to fetch user claims.', { error });
      return [];
    }
  }

  /** Gets a list of available course configurations. */
  async function getCourses(): Promise<Course[]> {
    $logger.info('Getting courses …');
    if (import.meta.server) return [];
    try {
      const response = useCollection<Course[]>(collection(db, 'courses'));
      if (response.error?.value) throw response.error.value;
      return (await response.promise?.value) as unknown as Course[];
    } catch (error) {
      $logger.error('Unable to fetch courses from Firebase.', { error });
      return [];
    }
  }

  /** Gets a list of available groups. */
  async function getUserGroups(): Promise<UserGroup[]> {
    $logger.info('Getting user groups …');
    if (import.meta.server) return [];
    try {
      const response = useCollection<UserGroup[]>(collection(db, 'groups'));
      if (response.error?.value) throw response.error.value;
      return await response?.promise?.value;
    } catch (error) {
      $logger.error('Unable to fetch groups.', { error });
      return [];
    }
  }

  /** Gets a list of available roles. */
  async function getUserRoles(): Promise<UserRole[]> {
    if (import.meta.server) return [];
    try {
      const response = useCollection<UserRole[]>(collection(db, 'roles'));
      if (response.error?.value) throw response.error.value;
      return await response.promise?.value;
    } catch (error) {
      $logger.error('Unable to fetch roles.', { error });
      return [];
    }
  }

  /**
   * Uploads a file to Firebase.
   * @link https://firebase.google.com/docs/storage/web/upload-files
   */
  async function handleUploadFile(
    uploadFileInfo: UploadFileInfo,
    contentRef: ContentReference
  ): Promise<UploadFileInfo | undefined> {
    if (!uploadFileInfo?.fullPath) throw new Error('No file to upload.');
    if (!contentRef) throw new Error('Content reference missing.');
    try {
      $logger.info('Saving file to Firebase:', uploadFileInfo);
      const fileReference = storageRef(
        firebaseStorage,
        generateFileReference(uploadFileInfo.name, getContentRef().task)
      );
      $logger.debug('Generated file reference:', fileReference);
      const { upload, url } = useStorageFile(fileReference);
      const file = await uploadFileInfo.file?.arrayBuffer();
      if (!file) return;
      const uploadMetadata = getUploadMetadata(uploadFileInfo, contentRef);
      await upload(file, uploadMetadata);
      $logger.debug('Upload complete:', url.value);
      uploadFileInfo.url = url.value ?? null;
      return uploadFileInfo;
    } catch (error) {
      $logger.error('Unable to upload file to Firebase.');
      $logger.error(error);
    }
  }

  async function isCourseAccessible(
    contentRef: ContentReference,
    uid?: User['uid']
  ): Promise<boolean> {
    if (!uid) return false;
    return $fetch<boolean>(`/api/course/${contentRef.course}`, {
      headers: { 'x-user-id': uid },
      method: 'GET',
      query: { t: Date.now() },
    });
  }

  /** Logs event to Google Analytics. */
  function logEvent({ data, eventName }: LogEvent): void {
    if (!$analytics) return;
    $logger.debug('Logging event:', eventName, data);
    firebaseLogEvent($analytics, eventName, data);
  }

  /**
   * Removes a file from Firestore based on a content reference.
   * @link https://firebase.google.com/docs/storage/web/delete-files
   */
  async function removeTaskFileFromFirestore(
    file: UploadFileInfo,
    contentRef: ContentReference
  ): Promise<void> {
    if (!file?.fullPath) throw new Error('No file to upload.');
    if (!contentRef?.task) throw new Error('Task reference missing.');
    try {
      $logger.debug('Removing file from Firebase:', file);
      const fileReference = storageRef(
        firebaseStorage,
        generateFileReference(file.name, contentRef.task)
      );
      $logger.debug('Generated file reference:', fileReference);
      await deleteObject(fileReference);
      $logger.success('File deleted:', fileReference);
    } catch (error) {
      $logger.error('Unable to delete file from Firebase.');
      $logger.error(error);
    }
  }

  /** Removes all files from from Firebase related tot a certain task. */
  async function removeTaskFiles(
    contentRef: ContentReference,
    files?: UploadFileInfo[]
  ): Promise<void> {
    try {
      $logger.info(
        'Removing files from remote using the following content reference:',
        contentRef
      );
      files = files ?? (await getFilesForEntry(contentRef.task));
      for (const file of files) {
        await removeTaskFileFromFirestore(file, contentRef);
      }
      $logger.success('Task files removed:', files);
    } catch (error) {
      $logger.error('Unable to delete files:', error);
    }
  }

  /** Saves user progress to Firestore. */
  async function saveEntryToFirestore(entry: UserProgress): Promise<void> {
    if (!userId.value) {
      $logger.warn(
        'Trying to save entry to Firebase without an authenticated user.'
      );
      return;
    }
    try {
      await setDoc(
        doc(db, userId.value, 'progress'),
        {
          tasks: arrayUnion(entry),
        },
        { merge: true }
      );
      logEvent({
        data: {
          module_id: entry.module,
          task_id: entry.task,
        },
        eventName: 'save_changes',
      });
    } catch (error) {
      $logger.error('Unable to save progress to Firebase:', error);
    }
  }

  /** Upload files to Firebase for a content entry. */
  async function uploadFiles(
    files: TaskFiles,
    contentRef: ContentReference
  ): Promise<TaskFiles> {
    if (!files?.length) {
      await removeTaskFiles(contentRef);
      return [];
    }
    $logger.debug('Preparing to save files to Firebase:', files);
    const modifiedFiles = (
      await Promise.all(
        files.map(async (f) => await handleUploadFile(f, contentRef))
      )
    ).filter((e) => Boolean(e)) as TaskFiles;
    $logger.debug('Got modified files:', modifiedFiles);
    return modifiedFiles;
  }

  return {
    getCourses,
    getEntryFromFirestore,
    getFilesForEntry,
    getModuleFiles,
    getModuleProgress,
    getUserClaims,
    getUserDiplomas,
    getUserGroups,
    getUserRoles,
    isCourseAccessible,
    logEvent,
    removeTaskFileFromFirestore,
    removeTaskFiles,
    saveEntryToFirestore,
    uploadFiles,
  };
}
